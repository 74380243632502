<template>
<div>
    <portfolio />
</div>
</template>

<script>
import portfolio from '../components/dashboard/Portfolio.vue'
export default {
    components: {
        portfolio
    }
}
</script>

<style>
