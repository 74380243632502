<template>
    <div>
        <Wrapper>
            <Sidebar slot="sidebar" />
            <Navbar slot="navbar" />
            <Footer slot="footer" />
            <div slot="content">
                <!-- <input type="text" v-model="filters.name.value"> -->
                <div class="container-fluid">
                    <div class="pre-loader text-center" v-if="portPreLoader ">
                        <span class="loader"></span>
                    </div>

                    <div class="empty-cart" v-if="portf.length < 1 ">
                        <h5 class="text-center pb-4" style="opacity:0.4">
                            {{tran.sorry_no_port}}
                        </h5>
                        <div>
                            <button class="theme-btn-2 mt-20" style="min-height:70px; min-width:200px"
                                @click.prevent="sendToMarketPlace">
                                {{tran.buy_products}}
                            </button>
                        </div>
                    </div>


                    <div v-if="portf.length > 0">
                        <div style="display:flex">

                            <div style="flex-basis:70%;" :class="{'horizontal-item-scroll':windowWidth < 768}">
                                <div style="display:flex" class=" mt-4 mb-3">
                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; min-width:103px; max-width:50px; justify-content:center; padding:0px 10px"
                                        class="mr-2 point-cursor"
                                        @click="saveActiveMarket('all');  saveActiveMarketString('All assets')">
                                        <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                        <p :class="{'active-market-color': activeMarketValue == 'all' }">Agriculture</p>
                                        <!-- <p :class="{'active-market-color': activeMarketValue == 'all' }" >All assets</p >  this is the updated code-->

                                    </div>

                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:200px; justify-content:center; 
    min-width: 103px;
padding:0px 10px" class="mr-2 point-cursor hide-elem"
                                        @click="saveActiveMarket('agriculture'); saveActiveMarketString('Agriculture')">
                                        <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->
                                        <p :class="{'active-market-color': activeMarketValue == 'agriculture' }">
                                            Agriculture </p>

                                    </div>


                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:200px; justify-content:center;
    min-width: 103px;
 padding:0px 10px" class="mr-4 point-cursor  hide-elem"
                                        @click="saveActiveMarket('euro_bond');; saveActiveMarketString('Fixed Income')">
                                        <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                        <p :class="{'active-market-color': activeMarketValue == 'euro_bond' }">Fixed
                                            Income </p>

                                    </div>
                                </div>
                            </div>

                            <div style="flex-basis:30%" class="mt-4" v-if="windowWidth > 769">
                                <div class="display-div point-cursor"
                                    style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px"
                                    :content="tran.ref_to_clipboard"
                                    v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"
                                    @click="copyRefCode($event)">
                                    <div>

                                        <img src="../../assets/images/speaker.png" height="20">

                                    </div>

                                    <div style="opacity:0; width:1px">
                                        <input class="ref-url" type="hidden"
                                            :value="`https://requid.me/${userProfileInfo.ref_code}`">
                                    </div>
                                    <div>
                                        <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>


                                    </div>

                                    <div class="text-right">

                                        <img src="../../assets/images/info.png" height="20"
                                            title="Invite friends using this Referral link and get 1% for every first investment they make."
                                            v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Mobile filter -->
                    <div style="background:#fff; padding:10px;border-radius:20px; position:sticky; top:60px; z-index:85"
                        :class="{'filter-active':filterActive}" class="tran-height-port"
                        v-if="windowWidth <= 769 && !portPreLoader && portf.length > 0">
                        <div style="display:flex; height:30px; overflow:hidden; min-width:100%; "
                            @click="activateFilter">
                            <div style="flex-basis:50%">
                                <p>Filter</p>

                            </div>
                            <div style="flex-basis: 50%;
  display:flex; 
    justify-content: flex-end;">
                                <div style="max-width:40px;">
                                    <i class="fa fa-chevron-down" :class="{'rotate-icon':filterActive}"></i>

                                </div>

                            </div>

                        </div>


                        <!-- <div style="display:flex;" class="mt-2" > 
 <div style="min-height:30px;flex-basis:50%;min-width:130px; border-radius:30px; display:flex; align-items:center; justify-content:center;" class="mr-2 point-cursor  hide-elem" @click="saveActiveMarket('naira')" :class="{'active-book-filter': activeMarketValue == 'naira'}">

<img src="../../assets/images/naira-logo.png" class="mb-1 mr-2"  height="15" >
                                                        <p :class="{'active-market-color': activeMarketValue == 'naira' }" >     Naira Assets (<span>&#8358;</span>)</p>

                                                    </div>

 <div style="min-height:30px; border-radius:30px; display:flex; min-width:130px; align-items:center; justify-content:center;  flex-basis:50%;" class="mr-2 point-cursor  hide-elem" @click="saveActiveMarket('dollar')" :class="{'active-book-filter': activeMarketValue == 'dollar'}">
     <img src="../../assets/images/dol-logo.png"  class="mb-1 mr-2" height="15" >  

   <p :class="{'active-market-color': activeMarketValue == 'dollar' }" >
       Dollar Assets ($)</p>

                                                    </div>


</div> -->
                        <hr style="margin:0.2rem 0rem">
                        <div style="min-height:45px;
 flex-basis:30%; display:flex;padding:5px; justify-content:flex-end" >

                            <div class="point-cursor" style="min-height:20px;
 display:flex;align-items:center;justify-content:center; flex-basis:50%"
                                :class="{'active-book-filter':activeTransType == 'active'}"
                                @click="saveTransType('active')">

                                <p>Active Investments</p>
                            </div>
                            <div class="point-cursor" :class="{'active-book-filter':activeTransType == 'both'}" style="min-height:20px;
 flex-basis:50%; display:flex;align-items:center;justify-content:center" @click="saveTransType('both')">
                                <p>Active & inactive</p>
                            </div>

                        </div>
                    </div>



                    <div class="mt-3" v-if="portf.length > 0">



                        <div style="display:flex;justify-content:space-between; min-height:50px; align-items:center"
                            v-if="windowWidth > 769">
                            <div style="display:flex; flex-basis:60%;">



                                <div style="flex-basis:32%">


                                    <div class="input-group" :class="{'flex-22': windowWidth < 1614}"
                                        @click.stop="showDropDown($event)">
                                        <div class="input-group-prepend" style="flex-grow:0">
                                            <div class="input-group-text" id="btnGroupAddon"
                                                style="border-right:1px solid #fff"><img
                                                    src="../../assets/images/filter.png" height="20"></div>
                                        </div>
                                        <input type="text" id="tagSearch" style="min-height: 50px; border-left:1px solid #fff;    border-top-right-radius:  2.25rem;
    border-bottom-right-radius:  2.25rem;" @keyup="clearTableData; saveActiveMarketString('All Asset')"
                                            class="form-control" placeholder="Filter by name or tag"
                                            aria-label="Input group example" aria-describedby="btnGroupAddon"
                                            v-model="filterString">
                                        <img src="../../assets/images/chevy.png" height="7" style="position: absolute;
    right: 12px;
    top: 21px; cursor:pointer">
                                        <i class="fa fa-times-circle point-cursor hide-btn"
                                            :class="{'show-btn':hoverState }" style="position: absolute;
    right: 30px;
    top: 18px; z-index:122" @click.stop="clearSearchTag"></i>

                                        <div class="hide-btn mt-1 animated fadeIn"
                                            style="overflow:hidden; position:absolute; top:48px; background:#fff; min-width:200px;box-shadow: 0 3px 10px 0 rgba(0,0,0,.1); z-index:1000; display:flex; justify-content:flex-start;"
                                            :class="{'show-btn':hoverState,'sm-height':allTags.length <= 3,'lng-height':allTags.length > 3 }">

                                            <div class="hover-item" v-for="tag in allTags" :key="tag.id"
                                                @click="searchTag(tag.name); saveActiveMarketString('All Assets')">

                                                <p>{{tag.name}}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <!-- <i class="fa fa-chevron-down" ></i> -->
                                </div>
                                <div style="display:flex; flex-basis:  33%">
                                    <div style="min-height:30px; background:#fff;min-width:130px; border-radius:30px; display:flex; align-items:center; max-width:50px; justify-content:center; padding:0px 10px;"
                                        class="point-cursor mr-2 hide-elem"
                                        @click="saveActiveMarket('naira'); saveActiveMarketString('Naira Asset')">
                                        <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                        <p :class="{'active-market-color': activeMarketValue == 'naira' }"> <img
                                                src="../../assets/images/naira-logo.png" height="17"> Naira Assets </p>

                                    </div>

                                    <div style="min-height:30px; background:#fff; border-radius:30px; display:flex; min-width:130px; align-items:center; max-width:200px; justify-content:center; padding:0px 10px"
                                        class=" point-cursor hide-elem"
                                        @click="saveActiveMarket('dollar') ; saveActiveMarketString('Dollar Asset')">
                                        <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->
                                        <p :class="{'active-market-color': activeMarketValue == 'dollar' }">
                                            <img src="../../assets/images/dol-logo.png" height="17">
                                            Dollar Assets ($)</p>

                                    </div>


                                </div>



                            </div>

                            <!-- <div style="flex-basis:40%; display:flex; justify-content:center" v-if="windowWidth > 1270" :class="{'flex-36': windowWidth < 1614}">

                                    <div  style="max-width:300px">
                                    <div class="display-div point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"   @click="copyRefCode($event)">
                                        <div>

                                            <img src="../../assets/images/speaker.png"  height="20">

                                         </div>

                                           <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                               
                                                <div class="text-right">

                                                    <img src="../../assets/images/info.png"  height="20" title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>
                                                </div>
                                            </div> -->
                            <div style="flex-basis:30%; display:flex; justify-content:flex-end; flex-wrap:wrap"
                                :class="{'flex-38': windowWidth < 1614}">
                                <div>
                                    <div
                                        style="display:flex;border: 0.5px solid #E6F4FF; border-radius:5px;background:#E6F4FF; min-height:38px;align-items:center;  padding: 5px 10px">

                                        <div>
                                            <img src="../../assets/images/activeinv.png" height="20">
                                        </div>

                                        <div>
                                            <p style="color: #828282; font-size:12px" class="text-right">Total value of
                                                active investments</p>

                                            <h5 class="text-right"
                                                v-if="activeMarketValue == 'naira' || activeMarketValue == 'agriculture' || activeMarketValue == 'all'">
                                                <span>&#8358;</span> {{formatAmount(portfolioSummary.current_value)}}
                                            </h5>
                                            <h5 class="text-right"
                                                v-if="activeMarketValue == 'dollar' || activeMarketValue == 'euro_bond'">
                                                $ {{formatAmount(portfolioSummary.current_value_inDollar)}}</h5>
                                        </div>
                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div>
                                    <div class="ml-2"
                                        style="display:flex;border: 0.5px solid #F1FAF0; border-radius:5px;background:#F1FAF0; min-height:38px;align-items:center; padding: 5px 10px">
                                        <div>

                                            <img src="../../assets/images/downld.png" height="20">

                                        </div>
                                        <div>
                                            <p style="color: #828282; font-size:12px" class="text-right">Total expected
                                                returns</p>

                                            <h5 class="text-right"
                                                v-if="activeMarketValue == 'naira' || activeMarketValue == 'agriculture' || activeMarketValue == 'all'">
                                                <span>&#8358;</span> {{formatAmount(portfolioSummary.total_value)}}</h5>
                                            <h5 class="text-right"
                                                v-if="activeMarketValue == 'dollar'|| activeMarketValue == 'euro_bond'">
                                                $ {{formatAmount(portfolioSummary.total_value_inDollar)}}</h5>
                                            <!-- <p class="text-right">retrieving value...</p> -->
                                        </div>
                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div style="display:flex; justify-content:space-around" class="pt-4" v-if="windowWidth > 769">

                        <div style="background:#fff; padding:10px; border-radius:10px" v-if="portf.length > 0">

                            <div class="pb-3"
                                style="display:flex; min-height:50px; align-items:center;border-bottom: 1px solid #E0E0E0;">

                                <div style="flex-basis:70%">

                                    <p style="color:#828282; font-size:18px" class="mr-2">{{activeMarketString}} </p>


                                </div>

                                <div style="min-height:50px;
border: 0.5px solid #BDBDBD;border-radius: 26px;  flex-basis:30%; display:flex;padding:5px; justify-content:flex-end" >

                                    <div class="point-cursor" style="min-height:20px;
 display:flex;align-items:center;justify-content:center; flex-basis:50%"
                                        :class="{'active-book-filter':activeTransType == 'active'}"
                                        @click="saveTransType('active')">

                                        <p>Active Investments</p>
                                    </div>
                                    <div class="point-cursor" :class="{'active-book-filter':activeTransType == 'both'}"
                                        style="min-height:20px;
 flex-basis:50%; display:flex;align-items:center;justify-content:center" @click="saveTransType('both')">
                                        <p>Active & inactive</p>
                                    </div>

                                </div>
                            </div>
                            <div class="table-responsive mb-4">
                                <table class="table-hover" v-if="portf.length > 0">
                                    <thead class="table-head">
                                        <!-- <th>{{tran.id}}</th> -->
                                        <th>{{tran.product}}</th>
                                        <th v-if="windowWidth > 1400">Units Left</th>
                                        <!--  <th>{{tran.amount}}</th> -->
                                        <th>Tags</th>

                                        <th>{{tran.amount}}</th>
                                        <th>{{tran.roi}}</th>
                                        <th>{{tran.total_return}}</th>

                                        <!-- <th>{{tran.reference}}</th> -->
                                        <th>{{tran.date_of_purchase}}</th>

                                        <th>{{tran.martures_on}}</th>
                                        <!-- <th>{{tran.action}}</th> -->
                                        <th>{{tran.transfer}}</th>
                                        <th></th>
                                    </thead>

                                    <tbody slot="body" name="custom-classes-transition"
                                        enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutUp"
                                        :duration="{ enter: 1000, leave: 1000 }" is="transition-group">



                                        <tr v-for="(prod, index) in filterPortfolioData(portf)" :key="prod.id"
                                            :class="{'active-tr':transferProductId == prod.id}"
                                            @mouseover.stop="saveActiveBtnId(prod)">
                                            <template>

                                                <td>

                                                    <p data-toggle="modal" :data-target="'#historyModal'+index"
                                                        @click="getObject(prod, index); getTransHistory(prod)"
                                                        class="add-link point-cursor" style="color:#58B46F"
                                                        title="Click to view full product details"
                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                                                        {{ prod.name }}</p>

                                                    <p
                                                        v-if="prod.units_available == prod.units_bought && windowWidth < 1400">
                                                        {{ prod.units_available }} {{tran.unit}}(s) Left
                                                    </p>
                                                    <p
                                                        v-if="prod.units_available !== prod.units_bought && windowWidth < 1400">
                                                        <span> {{prod.units_available }} </span><span
                                                            style="color:#848484">of {{prod.units_bought}} Left</span>
                                                    </p>

                                                </td>
                                                <td v-if="windowWidth > 1400">
                                                    <p v-if="prod.units_available == prod.units_bought">
                                                        {{ prod.units_available }}
                                                    </p>
                                                    <p v-if="prod.units_available !== prod.units_bought">
                                                        <span> {{prod.units_available }} </span><span
                                                            style="color:#848484">of {{prod.units_bought}} Left</span>
                                                    </p>

                                                </td>
                                                <td @mouseleave.stop="closeEvent">



                                                    <button class="tag-btn" v-if="prod.tag_name !== null"
                                                        @mouseover.stop="showRemTag(prod)"
                                                        @mouseleave.stop="hideRemTag">{{prod.tag_name}}<img
                                                            src="../../assets/images/remTag.png" height="20"
                                                            class="pt-1" v-if="remTag && tagId == prod.id && !tagLoader"
                                                            @click="deleteTag(prod, $event)" title="Remove tag"
                                                            v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'
                                                            style="float:right">
                                                        <img src="../../assets/images/tags.png"
                                                            :class="{'hide-btn':prod.id == tagId && tagLoader}"
                                                            height="20" v-else style="float:right" class="pt-1">
                                                        <i class="fa fa-spin fa-spinner mt-1"
                                                            v-if="tagId == prod.id && tagLoader"
                                                            style="float:right"></i>

                                                    </button>

                                                    <div style="min-height:30px; " v-if="prod.tag_name == null">

                                                        <button class="animated fadeIn hide-btn"
                                                            @click="saveDropDownId(prod)"
                                                            style="border-radius:23px; background:#fff; border:0.5px solid #000"
                                                            :class="{'show-btn':tagId == prod.id}">
                                                            Add tag
                                                            <img src="../../assets/images/chevy.png" height="7">
                                                        </button>

                                                        <div class="hide-btn mt-1 animated fadeIn"
                                                            :class="{'show-btn':dropDownId == prod.id}"
                                                            style="min-height:100px; position:absolute; background:#fff; min-width:200px;box-shadow: 0 3px 10px 0 rgba(0,0,0,.1); display:flex; justify-content:flex-start; border-radius:10px">

                                                            <div style="overflow:hidden; "
                                                                :class="{'sm-height':allTags.length <= 3,'lng-height':allTags.length > 3}">


                                                                <div class="hover-item" v-for="tag in allTags"
                                                                    :key="tag.id" @click="addTag(tag, prod, false)">


                                                                    <p style="flex-basis:85%">{{tag.name}}</p>
                                                                    <p style="flex-basis:15%"><i
                                                                            class="fa fa-spin fa-spinner"
                                                                            v-if="tagClicked == tag.id && tagLoader"></i>
                                                                    </p>
                                                                </div>
                                                            </div>



                                                            <div class="hover-item" style="padding:10px; border-bottom:1px solid #f2f2f2; 
                                                                                                border-bottom-left-radius:10px;
                                                                                                border-bottom-right-radius:10px;
                                                                                            background:#f2f2f2;"
                                                                data-toggle="modal" :data-target="'#tagModal'+index"
                                                                @click="openTagModal(prod, index)">
                                                                <p style="flex-basis:15%"> <img
                                                                        src="../../assets/images/plus.png" height="20">
                                                                </p>
                                                                <p style="flex-basis:85%">Create a tag</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </td>
                                                <td>
                                                    <!-- <template v-if="prod.asset_tag == 'euro_bond'">
                                                        ${{formatAmount(prod.amount)}}
                                                    </template> -->

                                                    <!-- <template v-if="prod.asset_tag == 'agriculture'">
 <span>&#8358;</span>{{ formatAmount(prod.amountInvested) }}
                                                                                </template> -->
                                                    <template>
                                                        <span>&#8358;</span>{{ formatAmount(prod.amountInvested) }}
                                                    </template>




                                                </td>
                                                <td>
                                                    {{ modifyRate(prod.roi) }}
                                                </td>

                                                <td>
                                                    <!-- <template v-if="prod.asset_tag == 'euro_bond'">
                                                                                   ${{formatAmount(prod.interestGottenInDollars)}}
                                                                                </template> -->

                                                    <!-- <template v-if="prod.asset_tag == 'agriculture'">   
                                                                                                <span>&#8358;</span>{{ formatAmount(prod.totalReturn) }}
                                                                                </template> -->
                                                    <template>
                                                        <span>&#8358;</span>{{ formatAmount(prod.totalReturn) }}
                                                    </template>

                                                </td>
                                                <!--  <td> {{prod.tranx_reference}} </td>-->
                                                <td>
                                                    {{ prod.created_at | formatDate}}
                                                </td>
                                                <td>
                                                    {{ prod.matures_at  | formatDate}}
                                                </td>

                                                <td>

                                                    <button class="point-cursor "
                                                        style="min-height:35px;padding:8px 14px; background:#E6F4FF; border:1px solid #E6F4FF; border-radius:5px; min-width:80px"
                                                        data-toggle="modal" :data-target="'#transfer' + index"
                                                        @click="getObject(prod, index)"
                                                        v-if="prod.pending_transfer_id == null && prod.isProductMatured == false && prod.units_available > 0 && prod.asset_tag !== 'euro_bond'"
                                                        title="Transfer investment"
                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                                                        <img src="../../assets/images/trf.png" height="20">

                                                    </button>

                                                    <button class="point-cursor "
                                                        style="min-height:35px;padding:8px 14px; background:#fff; border:1px solid #E0E0E0; border-radius:5px; min-width:80px"
                                                        data-toggle="modal" :data-target="'#cancelTransfer' + index"
                                                        @click="getObject(prod, index)"
                                                        v-if="prod.pending_transfer_id !== null && prod.isProductMatured == false "
                                                        title="Cancel transfer"
                                                        v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                                                        <img src="../../assets/images/close.png" height="20">

                                                    </button>

                                                </td>
                                                <td>
                                                    <div style="max-width:127px">


                                                        <button class="withdraw-btn sell-product" data-toggle="modal"
                                                            :data-target="'#sellModal' + index"
                                                            @click="getObject(prod, index)"
                                                            v-if=" prod.asset_tag == 'euro_bond'">

                                                            Liquidate
                                                            <span class="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"
                                                                v-if="cartItemSelectedId == prod.id && loader">
                                                            </span>
                                                        </button>

                                                        <button class="withdraw-btn sell-product" data-toggle="modal"
                                                            :data-target="'#sellModal' + index"
                                                            @click="getObject(prod, index)"
                                                            v-if=" prod.product_stage !== 'primary' && prod.product_stage !== 'secondary' && prod.units_for_sale < 1 && prod.isProductMatured == false && prod.units_available > 0 && prod.asset_tag !== 'euro_bond'">

                                                            {{tran.sell}}
                                                            <span class="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"
                                                                v-if="cartItemSelectedId == prod.id && loader">
                                                            </span>
                                                        </button>

                                                        <!-- <button class="withdraw-btn sell-product fade-elem-4" v-if="prod.product_stage == 'primary' && prod.status !== 'for_sale' || prod.product_stage == 'secondary' && prod.status !== 'forsale'"  :title="tran.you_cant_sell" v-tippy='{ arrow : true, arrowType : "round"}'>

                                                                {{tran.sell}}
                                                    </button> -->
                                                        <template
                                                            v-if="prod.units_for_sale > 0  && prod.isProductMatured == false">
                                                            <div class="display-div" data-toggle="modal"
                                                                :data-target="'#accruedInterest' + index"
                                                                @click="getObject(prod, index)">
                                                                <div>

                                                                    <span
                                                                        style="text-decoration:underline; color:#0094FF; cursor:pointer">
                                                                        {{ prod.total_sell_order_units - prod.units_for_sale}}
                                                                        /
                                                                        {{prod.total_sell_order_units}} {{tran.sold}}

                                                                    </span>
                                                                </div>
                                                                <div class="text-right pr-2 point-cursor">

                                                                    <img src="../../assets/images/chevr.png"
                                                                        height="12">

                                                                </div>
                                                            </div>

                                                        </template>

                                                        <div class="progress text-center mt-2"
                                                            v-if="prod.units_for_sale > 0 && prod.isProductMatured == false">
                                                            <div class="progress-bar" role="progressbar"
                                                                :class="{'width-100': prod.units_for_sale === 0, 'width-50': prod.units_available > 3 || prod.units_available >= 1, 'width-70': prod.total_sell_order_units - prod.units_for_sale == 1 || 2, ' bg-success': true}"
                                                                aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"
                                                                data-toggle="modal"
                                                                :data-target="'#accruedInterest' + index"
                                                                @click="getObject(prod, index)">

                                                                <template
                                                                    v-if="prod.units_available == 0 && prod.units_for_sale == 0">
                                                                    {{tran.sold}}

                                                                </template>

                                                            </div>
                                                        </div>

                                                        <div v-if="prod.isProductMatured && prod.staggered_payment_plan == null">
                                                            <button class="matured" style="font-size:13px">
                                                                {{tran.matured}}
                                                            </button>
                                                        </div>
                                                        <div v-if="prod.isProductMatured &&  prod.staggered_payment_plan "  data-toggle="modal" :data-target="'#historyModal'+index"  @click="getObject(prod, index); getTransHistory(prod)">
                                                            <button class="withdraw-btn " style="font-size:13px;color:#0094FF; border:1px solid #f2f2f2; background:#fff">
                                                               Matured
                                                            </button>
                                                        </div>
                                                    </div>

                                                </td>
                                            </template>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- <table class="table text-left"  style="overflow:hidden" v-show="returnFilter">
                                                            <thead class="cart-total-bg">
                                                                <tr>
                                                                    <td class="text-center">
                                                                        
                                                                        Sorry no results found for <b>{{filterString}}</b> </td>
                                                                </tr>
                                                            </thead>
                                                        </table> -->
                        </div>
                    </div>

                    <!-- Mobile View -->

                    <template v-if="windowWidth < 770 && portf.length > 0">
                        <div class="mobile-sum-div-port mt-3">
                            <div class="first-child mr-1" style="flex-basis:30%">
                                <img src="../../assets/images/portf.png" height="120">
                            </div>
                            <div style="flex-basis:70%">
                                <p class="pb-2">Total value of active investments</p>

                                <p class="fw-600 text-right mb-2" style="color:#828282 "
                                    v-if="activeMarketValue == 'naira' || activeMarketValue == 'agriculture' || activeMarketValue == 'all'">
                                    <b><span> &#8358;</span>{{formatAmount(portfolioSummary.current_value)}}</b></p>
                                <p class="fw-600 text-right mb-2" style="color:#828282 "
                                    v-if="activeMarketValue == 'dollar' || activeMarketValue == 'euro_bond'">
                                    <b>${{formatAmount(portfolioSummary.current_value_inDollar)}}</b></p>
                                <hr>
                                <p class="pb-2 pt-2">Total Expected Return </p>
                                <p class="fw-600 text-right mb-2" style="color:#828282 "
                                    v-if="activeMarketValue == 'naira' || activeMarketValue == 'agriculture' || activeMarketValue == 'all'">
                                    <b><span> &#8358;</span>{{formatAmount(portfolioSummary.total_value)}}</b></p>
                                <p class="fw-600 text-right mb-2" style="color:#828282 "
                                    v-if="activeMarketValue == 'dollar' || activeMarketValue == 'euro_bond'">
                                    <b>${{formatAmount(portfolioSummary.total_value_inDollar)}}</b></p>
                                <!-- <p class="fw-600 text-right mb-2" style="#828282 "><b>retrieving value...</b></p> -->

                                <!-- <p class="pb-2 pt-2">{{tran.returns}} (%)</p>
                                <p>{{portfolioSummary.avg_roi_percent}}</p>
                                <hr> -->
                                <template
                                    v-if="activeMarketValue == 'naira' || activeMarketValue == 'agriculture' || activeMarketValue == 'all'">
                                    <hr>
                                    <p class="pb-2 pt-2">{{tran.total_units_inv}}</p>
                                    <p class="text-right mb-2"> <b>{{formatNumber(portfolioSummary.total_units)}}</b>
                                    </p>
                                </template>

                            </div>
                        </div>
                        <div name="custom-classes-transition" enter-active-class="animated fadeInUp"
                            leave-active-class="animated fadeOutUp" :duration="{ enter: 1000, leave: 1000 }"
                            is="transition-group">
                            <div class="card card-mobile mb-3 mt-3" v-for="(prod, index) in filterPortfolioData(portf)"
                                :key="prod.id" :class="{'active-tr':transferProductId == prod.id}">
                                <div class="row no-gutters">

                                    <!--    
                                 <div class="col-md-2 col-2" style="max-width: 15.666667%;">
                                 <img src="../../assets/images/img-tag.png"  height="60">
                                </div>   -->

                                    <div class="col-md-6 col-6 pl-2 pt-2">
                                        <div style="display:inline-block">
                                            <h5 data-toggle="modal" :data-target="'#historyModal'+index"
                                                @click="getObject(prod, index); getTransHistory(prod)"
                                                class="card-title add-link point-cursor" style="color:#58B46F">
                                                {{prod.name}}</h5>
                                        </div>


                                        <p class="card-text" v-if="prod.units_available == prod.units_bought">
                                            {{ prod.units_available }} {{tran.unit}}(s)
                                        </p>
                                        <p class="card-text" v-if="prod.units_available !== prod.units_bought">
                                            {{prod.units_available }} of {{prod.units_bought}} {{tran.unit}}(s) Left
                                        </p>

                                        <p class="card-text">
                                            <span style="  font-size: 12px;"> <b>{{tran.matures}}</b> :</span>
                                            <span class="card-text" style="  font-size: 12px;">
                                                {{prod.matures_at | formatDate }} </span>
                                        </p>

                                        <div>
                                            <button class="tag-btn mt-2 mb-2" style="font-size:12px"
                                                v-if="prod.tag_name !== null && !prod.isProductMatured">
                                                {{prod.tag_name}}
                                                <img src="../../assets/images/remTag.png" class="pl-3" height="20"
                                                    :class="{'hide-btn': tagId == prod.id && tagLoader }"
                                                    @click.stop="deleteTag(prod, $event)" title="Remove tag"
                                                    v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                                                <i class="fa fa-spin fa-spinner ml-3"
                                                    v-if="tagId == prod.id && tagLoader"></i>
                                                <!-- <img src="../../assets/images/tags.png"  height="20"> -->
                                            </button>

                                        </div>


                                        <button class="mt-2 mb-2"
                                        style="background:#fff; border: 1px solid #f4f4f4;min-width: 71px; min-height:30px; border-radius:5px; color:#0094FF;; font-size:12px;"
                                        v-if="prod.isProductMatured &&  prod.staggered_payment_plan "  data-toggle="modal" :data-target="'#historyModal'+index"  @click="getObject(prod, index); getTransHistory(prod)">

                                        Matured
                                        </button>
                                        <button class="mt-2 mb-2"
                                            style="background:#e9ecef; border: 1px solid #e9ecef;min-width: 71px; min-height:30px; border-radius:5px; color:#000; font-size:12px;"
                                            v-if="prod.isProductMatured && prod.staggered_payment_plan == null">

                                            {{tran.matured}}

                                        </button>
                                        <div @mouseleave.stop="closeEvent" style="max-width: 92px;">
                                            <button class="animated fadeIn mt-1 mb-2 "
                                                style="border-radius:23px; background:#fff; border:0.5px solid #000;min-width:71px;font-size:12px;    min-height: 25px;"
                                                @click.stop="saveActiveBtnId(prod)"
                                                v-if="prod.isProductMatured == false && prod.tag_name == null">Add tag
                                                <img src="../../assets/images/chevy.png" class="pl-2" height="7">
                                            </button>

                                            <div class="hide-btn mt-1 animated fadeIn"
                                                :class="{'show-btn':tagId == prod.id && prod.tag_name == null}"
                                                style="min-height:100px; position:absolute; background:#fff; min-width:200px;box-shadow: 0 3px 10px 0 rgba(0,0,0,.1); display:flex; justify-content:flex-start; z-index:23;border-radius:10px">

                                                <div style="overflow:hidden; "
                                                    :class="{'sm-height':allTags.length <= 3,'lng-height':allTags.length > 3 }">
                                                    <div class="hover-item" v-for="tag in allTags" :key="tag.id"
                                                        @click="addTag(tag, prod, false)">


                                                        <p style="flex-basis:85%">{{tag.name}}</p>
                                                        <p style="flex-basis:15%"><i class="fa fa-spin fa-spinner"
                                                                v-if="tagClicked == tag.id && tagLoader"></i></p>
                                                    </div>


                                                </div>
                                                <div class="hover-item"
                                                    style="padding:10px; border-bottom:1px solid #f2f2f2; 
                                                                                             border-bottom-left-radius:10px;
                                                                                                border-bottom-right-radius:10px;background:#f2f2f2;"
                                                    data-toggle="modal" :data-target="'#tagModal'+index"
                                                    @click="openTagModal(prod, index)"
                                                    @mouseover.stop="saveActiveBtnId(prod)"
                                                    @mouseleave.stop="closeEvent">
                                                    <p style="flex-basis:15%"> <img src="../../assets/images/plus.png"
                                                            height="20"> </p>
                                                    <p style="flex-basis:85%">Create a tag</p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6 col-6 pr-2 pt-2 animated"
                                        style=" display:flex; align-items:flex-end; flex-flow:column">
                                        <div style="display:inline-block">
                                            <p>
                                                <b> <span>
                                                        {{tran.roi}} :
                                                    </span>
                                                </b>
                                                <span>
                                                    {{modifyRate(prod.roi)  || modifyRate(prod.resell_roi)}}
                                                </span>

                                            </p>
                                        </div>


                                        <p class="text-left">
                                            <span>
                                                <b> {{tran.amount}} :</b>
                                            </span>

                                            <!-- <span v-if="prod.asset_tag == 'agriculture'"> <span> &#8358;</span>{{formatAmount(prod.amountInvested)}}
                                                                            </span> -->
                                            <span> <span> &#8358;</span>{{formatAmount(prod.amountInvested)}}
                                            </span>
                                            <!-- <span v-if="prod.asset_tag == 'euro_bond'">${{formatAmount(prod.amount)}}
                                            </span> -->
                                        </p>
                                        <p class="text-left">
                                            <span><b>{{tran.total_return}} :</b>
                                            </span>
                                            <!-- <span v-if="prod.asset_tag == 'agriculture'"> <span> &#8358;</span>{{formatAmount(prod.totalReturn)}}
                                                                            </span> -->
                                            <span> <span> &#8358;</span>{{formatAmount(prod.totalReturn)}}
                                            </span>
                                            <span
                                                v-if="prod.asset_tag == 'euro_bond'">${{formatAmount(prod.interestGottenInDollars)}}
                                            </span>

                                        </p>


                                        <div class="display-div" style="justify-content:flex-end;min-width: 84%;">
                                            <div class="mt-2"
                                                style="cursor:pointer !important; display: flex;justify-content: flex-end;"
                                                v-if="prod.pending_transfer_id == null && prod.isProductMatured == false && prod.units_available > 0 && prod.asset_tag !== 'euro_bond'">

                                                <button
                                                    style="background:#E5F4FF; border: 1px solid #E5F4FF;min-width: 71px; min-height:30px; border-radius:5px; color:#0094FF; font-size:12px;"
                                                    data-toggle="modal" :data-target="'#transfer' + index"
                                                    @click="getObject(prod, index)"
                                                    v-if="prod.status !== 'sys_trx_rvsl' &&  prod.status !== 'sys_rvsl'">

                                                    {{tran.transfer}}

                                                </button>


                                            </div>

                                            <button class="point-cursor mt-2"
                                                style="min-height:30px; background:#fff; border:1px solid #E0E0E0; border-radius:5px; min-width:70px"
                                                data-toggle="modal" :data-target="'#cancelTransfer' + index"
                                                @click="getObject(prod, index)"
                                                v-if="prod.pending_transfer_id !== null && prod.isProductMatured == false "
                                                title="Cancel transfer"
                                                v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>
                                                <img src="../../assets/images/close.png" height="20">
                                            </button>

                                            <div class="ml-1 mt-2"
                                                style=" display:flex; justify-content:flex-end; cursor:pointer"
                                                data-toggle="modal" :data-target="'#sellModal' + index"
                                                @click="getObject(prod, index)" v-if="prod.asset_tag == 'euro_bond' ">

                                                <button
                                                    style="background:#E4FAE1; border: 1px solid #E4FAE1;min-width: 71px; min-height:30px; border-radius:5px; color:#58B46F; font-size:12px;">

                                                    Liquidate

                                                </button>

                                            </div>
                                            <div class="ml-1 mt-2"
                                                style=" display:flex; justify-content:flex-end; cursor:pointer"
                                                data-toggle="modal" :data-target="'#sellModal' + index"
                                                @click="getObject(prod, index)"
                                                v-if="prod.product_stage !== 'primary' && prod.product_stage !== 'secondary' && prod.units_for_sale < 1  && prod.isProductMatured == false && prod.units_available > 0 && prod.asset_tag !== 'euro_bond' ">

                                                <button
                                                    style="background:#E4FAE1; border: 1px solid #E4FAE1;min-width: 71px; min-height:30px; border-radius:5px; color:#58B46F; font-size:12px;">

                                                    {{tran.sell}}

                                                </button>

                                            </div>
                                            <button class="tag-btn mt-2" style="font-size:12px"
                                                v-if="prod.tag_name !== null && prod.isProductMatured">
                                                {{prod.tag_name}}
                                                <img src="../../assets/images/tags.png" height="20">
                                            </button>

                                            <div class="text-right ml-1 mt-2"
                                                v-if="prod.units_for_sale > 0 && prod.isProductMatured == false"
                                                style="max-width: 103px; cursor:pointer; overflow:hidden; font-size:12px; min-height:30px">
                                                <template
                                                    v-if="prod.units_for_sale > 0  && prod.isProductMatured == false">
                                                    <div class="display-div" data-toggle="modal"
                                                        :data-target="'#accruedInterest' + index"
                                                        @click="getObject(prod, index)">
                                                        <div class="text-left">

                                                            <span
                                                                style="text-decoration:underline; color:#0094FF; cursor:pointer">
                                                                {{ prod.total_sell_order_units - prod.units_for_sale}} /
                                                                {{prod.total_sell_order_units}} {{tran.sold}}

                                                            </span>
                                                        </div>
                                                        <div class="text-right  point-cursor">

                                                            <img src="../../assets/images/chevr.png" height="12">

                                                        </div>
                                                    </div>

                                                </template>
                                                <div class="progress mt-1 text-center"
                                                    v-if="prod.units_for_sale > 0 && prod.isProductMatured == false">
                                                    <div class="progress-bar" role="progressbar"
                                                        :class="{'width-100': prod.units_for_sale === 0, 'width-50': prod.units_available > 3 || prod.units_available >= 1, 'width-70': prod.total_sell_order_units - prod.units_for_sale == 1 || 2, ' bg-success': true}"
                                                        aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"
                                                        data-toggle="modal" :data-target="'#accruedInterest' + index"
                                                        @click="getObject(prod, index)">

                                                        <template
                                                            v-if="prod.units_available == 0 && prod.units_for_sale == 0">
                                                            {{tran.sold}}

                                                        </template>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Sell Modal -->
                    <div class="modal fade" :id="'sellModal' + indexFromTable" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" data-backdrop="static" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content"
                                style="background-image: linear-gradient(180deg, #23AEE6 0%, #44CFA9 91.31%, #FFFFFF 91.35%);">
                                <div class="modal-header text-right">
                                    <h4 class="modal-title text-center" id="exampleModalLongTitle" style="color:#fff"
                                        v-if="sellProd && portfolioObjectData.asset_tag !== 'euro_bond'">{{tran.sell}}
                                    </h4>
                                    <h4 class="modal-title text-center" id="exampleModalLongTitle" style="color:#fff"
                                        v-if="portfolioObjectData.asset_tag == 'euro_bond'">Liquidate</h4>
                                    <h4 class="modal-title text-center" style="font-weight:300; color:#fff"
                                        id="exampleModalLongTitle" v-if="sellProdSum">{{tran.confirm_sale}}</h4>

                                    <img src="../../assets/images/close.png" height="25" class="point-cursor"
                                        data-dismiss="modal" @click="cancelSaleModal" aria-label="Close">

                                </div>
                                <div class="modal-body ">

                                    <template v-if="sellProd">







                                        <h5 style="color:#FFE8B3"><b>{{portfolioObjectData.name}} </b></h5>
                                        <p style="color:#fff" class=" mb-4">{{portfolioObjectData.atp_name}}</p>



                                        <div style="display:flex; align-items:center; min-height:40px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/sellicon.png" height="30">
                                            </div>
                                            <div class="ml-2 pb-3"
                                                style="color:#B7F2B6; flex-basis:90% ; position:relative"
                                                v-if="portfolioObjectData.asset_tag == 'euro_bond'">
                                                <span> ${{formatAmount(portfolioObjectData.amount)}}</span>

                                            </div>

                                            <div class="ml-2 pb-3"
                                                style="color:#B7F2B6; flex-basis:90% ; position:relative"
                                                v-if="portfolioObjectData.asset_tag !== 'euro_bond'">
                                                <span><span>&#8358;</span>
                                                    {{formatAmount(portfolioObjectData.units_available * 5000)}}</span>
                                                <span class="pl-1 pr-1"> - </span>
                                                <span> {{portfolioObjectData.units_available}} </span>
                                                <span class="pl-1"> unit(s)</span>
                                            </div>
                                        </div>

                                        <div style="display:flex; align-items:center; min-height:40px"
                                            v-if="portfolioObjectData.asset_tag !== 'euro_bond'">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/cash2.png" height="15">
                                            </div>
                                            <div class="ml-2 pb-3"
                                                style="color:#B7F2B6; flex-basis:90% ; position:relative">
                                                <span
                                                    v-if="portfolioObjectData.interestGotten !== undefined"><span>&#8358;</span>
                                                    {{formatAmount(portfolioObjectData.interestGotten)}}</span>

                                                <p style="color:#fff;"> Expected return at maturity </p>
                                            </div>
                                        </div>
                                        <div style="display:flex; align-items:center; min-height:40px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/cal2.png" height="20">
                                            </div>
                                            <div class="ml-2 pb-3"
                                                style="color:#fff; flex-basis:90% ; position:relative">
                                                <span>Matures : </span>

                                                <span style="color:#B7F2B6">
                                                    {{portfolioObjectData.matures_at | formatDate}} </span>
                                            </div>
                                        </div>
                                        <div style="display:flex; align-items:center; min-height:40px"
                                            v-if="portfolioObjectData.asset_tag !== 'euro_bond'">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/earned.png" height="25">
                                            </div>
                                            <div class="ml-2 pb-3"
                                                style="color:#B7F2B6; flex-basis:90% ; position:relative">
                                                <span>Already earned : </span>

                                                <span v-if="portfolioObjectData.current_tranx_worth !== undefined">
                                                    <span>&#8358;</span>
                                                    {{formatAmount(portfolioObjectData.current_tranx_worth)}}
                                                    today</span>
                                            </div>
                                        </div>




                                    </template>



                                    <template v-if="sellProdSum && portfolioObjectData.asset_tag !== 'euro_bond'">

                                        <p class="pb-4" style="color:#FFF; ">You are about to sell this item</p>






                                        <div style="display:flex; align-items:center; min-height:40px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/pay.png" height="25">
                                            </div>
                                            <div class="ml-2 pb-3"
                                                style="color:#FFE8B3; flex-basis:90% ; position:relative; border-bottom: 1px solid #E4FAE1;">


                                                <span>{{unitsToSell}} unit(s) of {{portfolioObjectData.name}} for
                                                    <span>&#8358;</span> {{formatAmount(totalAmountToSell)}}</span>
                                            </div>

                                        </div>

                                        <div style="display:flex; align-items:center; min-height:40px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/cash2.png" height="15">
                                            </div>
                                            <div class=" ml-2  pt-3 pb-3"
                                                style="color:#fff; flex-basis:90% ; position:relative; border-bottom: 1px solid #E4FAE1;">


                                                <span> <span style="color:#FFE8B3">
                                                        <span>&#8358;</span>{{formatAmount(saleAmountAfterDeduction)}}
                                                    </span> will be paid to your wallet upon sale and deductions.</span>
                                            </div>

                                        </div>
                                        <div style="display:flex; align-items:center; min-height:40px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/cal2.png" height="25">
                                            </div>
                                            <div class=" ml-2  pt-3 pb-3"
                                                style="color:#fff; flex-basis:90% ; position:relative;">


                                                <span> <span style="color:#FFE8B3">
                                                        <span>&#8358;</span>{{formatAmount(estimatedInterest)}} </span>
                                                    ROI will be paid to your wallet upon maturity.</span>
                                            </div>

                                        </div>








                                    </template>

                                </div>

                                <div class="trans-footer" style="background:#F1FAF0;padding:20px;"
                                    v-if="portfolioObjectData.asset_tag == 'euro_bond'">
                                    <p class="text-center pt-2">To confirm this transaction, enter your pin</p>
                                    <div class="eye-div widthraw-input">
                                        <input :type="hideInput" v-model="userPin" class="form-input text-center "
                                            maxlength="4" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');">

                                        <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                        <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>

                                    </div>
                                    <p class="pt-4 text-center" style="font-size:13px; color:#4F4F4F"
                                        v-if="portfolioObjectData.asset_tag !== 'euro_bond'">{{tran.capital_will_be}}
                                    </p>

                                    <p class="pt-4 text-center" style="font-size:13px; color:#4F4F4F"
                                        v-if="portfolioObjectData.asset_tag == 'euro_bond'">
                                        {{tran.capital_will_be_paid_euro}} </p>
                                </div>

                                <div class="trans-footer" style="background:#F1FAF0;padding:20px;" v-if="sellProdSum">
                                    <p class="text-center pt-2">To confirm this transaction, enter your pin</p>
                                    <div class="eye-div widthraw-input">
                                        <input :type="hideInput" v-model="userPin" class="form-input text-center "
                                            maxlength="4" autocomplete="off" readonly
                                            onfocus="this.removeAttribute('readonly');">

                                        <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                        <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>

                                    </div>
                                    <p class="pt-4 text-center" style="font-size:13px; color:#4F4F4F"
                                        v-if="portfolioObjectData.asset_tag !== 'euro_bond'">{{tran.capital_will_be}}
                                    </p>

                                    <p class="pt-4 text-center" style="font-size:13px; color:#4F4F4F"
                                        v-if="portfolioObjectData.asset_tag == 'euro_bond'">
                                        {{tran.capital_will_be_paid_euro}} </p>
                                </div>
                                <!-- <div style="background:#f2f2f2; padding:15px">

                                <p class="text-center" style="color:#F2A800">{{tran.once_Sale_has}} </p>

                            </div>-->
                                <div class="trans-footer" style="background:#F1FAF0;padding:20px;"
                                    v-if="sellProd && portfolioObjectData.asset_tag !== 'euro_bond'">

                                    <p class="text-center pb-3">How much unit(s) would you like to sell</p>

                                    <div style="display:flex; justify-content:space-between">

                                        <div style="flex-basis:20%" class="mr-2">
                                            <input type="number"
                                                @keyup="checkUnitsToSell(portfolioObjectData.units_available, portfolioObjectData.units_available*5000, portfolioObjectData.roi, portfolioObjectData.current_tranx_worth, portfolioObjectData)"
                                                placeholder="units" v-model="unitsToSell"
                                                style="border:1px solid #58B46F;; border-radius:3px; padding:10px; max-width:120px">
                                        </div>
                                        <div class="text-center"
                                            style="background:#E4FAE1; flex-basis:70%; padding:10px; border-radius:3px">
                                            <p>{{formatAmount(totalAmountToSell)}}</p>
                                        </div>

                                    </div>
                                    <div class="pt-4">
                                        <p style="color:#828282">Remaining value upon sale</p>
                                        <div style="display:flex">
                                            <p> <span>&#8358;</span> {{formatAmount(remainvalueUponSale)}}</p>
                                            <p class="pl-1 pr-1"> - </p>
                                            <p>{{remainingUnits }} unit(s)</p>

                                        </div>
                                    </div>
                                    <div class="pt-2">
                                        <p style="color:#828282">Estimated interest upon maturity</p>
                                        <p> <span>&#8358;</span> {{formatAmount(estimatedInterest)}}</p>
                                    </div>

                                    <p v-if="portfolioObjectData.asset_tag !== 'euro_bond'" class="pt-4 text-center"
                                        style="font-size:13px; color:#4F4F4F"> {{tran.capital_will_be}} </p>
                                    <p class="pt-4 text-center" style="font-size:13px; color:#4F4F4F"
                                        v-if="portfolioObjectData.asset_tag == 'euro_bond'">
                                        {{tran.capital_will_be_paid_euro}} </p>
                                </div>











                                <div class="modal-footer trans-footer" style="background:#fff">
                                    <div class="display-div">
                                        <div data-dismiss="modal" @click.prevent="cancelSaleModal" aria-label="Close"
                                            v-if="sellProd">
                                            <p style="color:#000">{{tran.cancel}}</p>
                                        </div>
                                        <div v-if="sellProdSum"
                                            style="display: flex;justify-content: flex-start;text-align: left;"
                                            @click.stop="editSale">
                                            <p style="color:#000"><img src="../../assets/images/back4.png" height="11"
                                                    class="point-cursor pr-4">{{tran.edit_det}}</p>
                                        </div>
                                        <div @click.prevent="gotoSellProdSum"
                                            v-if="sellProd && portfolioObjectData.asset_tag !== 'euro_bond'"
                                            class="text-right" :class="{'fade-elem':unitsToSell == ''}">
                                            <p style="color:#0094FF">{{tran.proceed}}</p>
                                        </div>
                                        <div v-if="sellProdSum" class="text-right">
                                            <p style="color:#0094FF" @click.prevent="sellInvestment">{{tran.sell}} <i
                                                    class="fa fa-spin fa-spinner" v-if="transferInvestmentLoader"></i>
                                            </p>
                                        </div>
                                        <div v-if="portfolioObjectData.asset_tag == 'euro_bond'" class="text-right">
                                            <p style="color:#0094FF" @click.prevent="sellInvestment"> Liquidate <i
                                                    class="fa fa-spin fa-spinner" v-if="transferInvestmentLoader"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Transfer modal-->
                    <div class="modal fade" :id="'transfer' + indexFromTable" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" data-backdrop="static" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content"
                                style="background-image: linear-gradient(180deg, #23AEE6 0%, #44CFA9 91.31%, #FFFFFF 91.35%);">
                                <div class="modal-header text-right" style="color:#fff">
                                    <h5 class="modal-title text-center" id="exampleModalLongTitle"
                                        v-if="sellProd && !editTransferDetailBtn">{{tran.transfer}} Investment</h5>
                                    <h5 class="modal-title text-center" id="exampleModalLongTitle"
                                        v-if="editTransferDetailBtn">Review Investment Transfer</h5>


                                    <img src="../../assets/images/close.png" height="25" class="point-cursor"
                                        data-dismiss="modal" @click="resetTransferState" aria-label="Close">

                                </div>
                                <div class="modal-body pt-1">
                                    <template v-if="transferDetail">
                                        <h5 class="modal-title pb-2" style="color:#fff" id="exampleModalLongTitle"
                                            v-if="sellProd && !editTransferDetailBtn"> {{portfolioObjectData.name}}</h5>

                                        <p class="modal-title pt-2 pb-2" style="color:#fff"
                                            v-if="sellProd && !editTransferDetailBtn"> Asset details</p>



                                        <div style="display:flex; ">

                                            <div style="flex-basis:28%">
                                                <input type="number"
                                                    @keyup="checkUnitsToSell(portfolioObjectData.units_available, portfolioObjectData.units_available*5000, portfolioObjectData.roi)"
                                                    v-model="unitsToSell" placeholder="units"
                                                    style="border:1px solid #BDBDBD; border-radius:3px; padding:10px; background:#E6F4FF; width:104px;border: 0.5px solid #0094FF;">
                                            </div>
                                            <div class="text-center"
                                                style="background: rgba(255, 255, 255, 0.1); flex-basis:72%; padding:10px; border-radius:3px; color:#fff; display:flex; justify-content:center;align-items:center">
                                                <p><span>&#8358;</span>{{formatAmount(totalAmountToSell)}}</p>
                                            </div>

                                        </div>

                                        <div class="mt-4">
                                            <p style="color:#fff">{{tran.enter_recepient}}</p>
                                            <input type="email" class="form-input" @input="transferInProgress"
                                                v-model="transferRecipientEmail" placeholder="email address"
                                                style="background: rgba(255, 255, 255, 0.1) !important; border-bottom:2px solid #fff;box-shadow: 0 1.1px 0 0 #fff; color:#fff; font-weight:300;min-height:50px">

                                            <i class="fa fa-search" @click="checkTransferRecepient"
                                                style="color:#fff;position: absolute;right: 45px; bottom: 34px;"></i>
                                        </div>

                                    </template>
                                    <template v-if="editTransferDetailBtn">
                                        <div style="display:flex; align-items:center; min-height:60px">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/gift2.png" height="30">
                                            </div>
                                            <div class="ml-4 pb-3"
                                                style="color:#fff; flex-basis:90% ; position:relative">
                                                <h5> {{portfolioObjectData.name}}</h5>
                                                <p style="color:#E4FAE1">{{portfolioObjectData.atp_name}}</p>
                                                <img src="../../assets/images/line2.png" height="2"
                                                    style="position:absolute; top:90%">
                                            </div>
                                        </div>

                                        <div style="display:flex; align-items:center; min-height:60px;">
                                            <div style="flex-basis:10%;">
                                                <img src="../../assets/images/gift2.png" height="30" style="opacity:0">
                                            </div>
                                            <div class="ml-4 pb-3"
                                                style="color:#fff; flex-basis:90%; border-bottom: 1px solid #E4FAE1;">
                                                <p>{{unitsToSell}} unit(s) -
                                                    <span>&#8358;</span>{{formatAmount(totalAmountToSell)}}</p>

                                            </div>
                                        </div>
                                        <div class="mt-2" style="display:flex; align-items:center; min-height:60px;">
                                            <div class="pb-3" style="flex-basis:10%;">
                                                <img src="../../assets/images/person.png" height="30">
                                            </div>
                                            <div class="ml-4 pb-3"
                                                style="color:#fff; flex-basis:90%; border-bottom: 1px solid #E4FAE1;">
                                                <p style="color:#E4FAE1">From your portfolio to:</p>
                                                <h5> {{recepientTransferDetail.first_name}} &nbsp;
                                                    {{ recepientTransferDetail.last_name }}
                                                </h5>
                                                <p v-if="recepientTransferDetail.message !== null">
                                                    {{transferRecipientEmail}} (will be invited to reQuid)</p>

                                            </div>
                                        </div>
                                        <div class="mt-2" style="display:flex; align-items:center; min-height:60px;">
                                            <div style="flex-basis:10%;">
                                                <img src="../../assets/images/person2.png" height="30">
                                            </div>
                                            <div class="ml-4"
                                                style="color:#fff; flex-basis:90%; display:flex; align-items:flex-start; justify-content:center; flex-flow:column; min-height:60px;">
                                                <h5 style="color:#FFE8B3;">
                                                    &#8358;{{formatAmount(recepientTransferDetail.charge)}}</h5>
                                                <p>Transfer fees <span style="color:#FFE8B3">Charged to wallet</span>
                                                </p>


                                            </div>
                                        </div>




                                    </template>

                                </div>
                                <div class="pin-div pr-4 pl-4 pb-4 mt-4 trans-footer" style="background:#E4FAE1"
                                    v-if="editTransferDetailBtn">
                                    <template v-if="userProfileInfo.has_pin">

                                        <p class="text-center pt-4">To confirm this transaction, enter your PIN</p>
                                        <div class="eye-div widthraw-input">
                                            <input :type="hideInput" v-model="userPin" class="form-input text-center"
                                                maxlength="4" autocomplete="off" readonly
                                                onfocus="this.removeAttribute('readonly');"
                                                style="border-bottom:1px solid #828282;">

                                            <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                            <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>

                                        </div>

                                    </template>

                                    <template v-else>
                                        <center>
                                            <p class="pt-3">you will need a PIN to confirm this transaction</p>
                                            <button class="create-pin-btn mt-2" @click="createPin">
                                                Create a PIN
                                            </button>
                                        </center>
                                    </template>
                                </div>
                                <div class="modal-footer trans-footer" style="background:#fff">
                                    <div class="display-div">
                                        <div v-if="transferDetail" style="display:flex; justify-content:flex-start"
                                            class="text-left">
                                            <img src="../../assets/images/info.png" height="18">
                                        </div>
                                        <div v-if="transferDetail" style="flex-basis:70%">
                                            <p class="text-left" style="color:#828282"> User will get a notification
                                                about your transfer</p>
                                        </div>
                                        <div v-if="editTransferDetailBtn">
                                            <p style="color:#000" @click="editTransferDetail"> <img
                                                    src="../../assets/images/back4.png" height="11"
                                                    class="point-cursor pr-4"> {{tran.edit_det}}</p>
                                        </div>
                                        <div v-if="transferDetail" style="flex-basis:20%">

                                            <p style="color:#0094FF" class="text-right" @click="checkTransferRecepient"
                                                :class="{'fade-elem':stopTransfer}">
                                                <span v-if="!transferInvestmentLoader">{{tran.proceed}} </span><i
                                                    class="fa fa-spin fa-spinner" v-if="transferInvestmentLoader"></i>
                                            </p>

                                        </div>
                                        <div v-if="editTransferDetailBtn">
                                            <p style="color:#0094FF" class="text-right" @click="transferInvestment"
                                                :class="{'fade-elem':userProfileInfo.has_pin == false}">
                                                {{tran.complete_transfer}} <i class="fa fa-spin fa-spinner"
                                                    v-if="transferInvestmentLoader"></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- accrued Interest modal -->
                    <div class="modal fade" :id="'accruedInterest' + indexFromTable" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header text-right">
                                    <h6 class="modal-title" id="exampleModalLongTitle"> <span class="fw-600">
                                            {{ portfolioObjectData.name }} </span>
                                        <span class="text-center mb-4" style="color:#828282">{{ tran.sale_in }}</span>,
                                        <span v-if="windowWidth > 475"> started
                                            {{portfolioObjectData.recent_sale_date|formatDate}}</span></h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">

                                    <div>
                                        <span>{{ portfolioObjectData.units_for_sale}} unit(s) -
                                        </span><span>&#8358;</span><span
                                            class="fw-600">{{formatNumber(portfolioObjectData.units_for_sale * 5000)}}
                                        </span><span>{{tran.up_for_sale}}</span>

                                    </div>
                                    <div class="pt-4">
                                        {{ portfolioObjectData.total_sell_order_units - portfolioObjectData.units_for_sale}}
                                        /
                                        {{portfolioObjectData.total_sell_order_units}} unit(s) {{tran.sold}}

                                    </div>

                                    <!-- <p class="pt-4" >{{tran.expect_roi}} : <span style="color:#0094FF">&#8358;</span><span style="color:#0094FF" class="fw-600">{{formatAmount(accruedMinAmountForResell)}}</span></p>-->
                                </div>
                                <div class="modal-footer text-center" style="background:#fff">
                                    <div class="display-div" style="align-items:center">
                                        <div style="background:#fafafa; padding:15px;color:#000; flex-basis:80%">

                                            <p class="text-center"><i class="fa fa-info-circle"></i>
                                                {{tran.capital_will_be}} </p>

                                        </div>

                                        <div style="flex-basis:20%">
                                            <p style="color:#0094FF" @click="cancelSale">Yes, stop sale<i
                                                    class="fa fa-spin fa-spinner" v-if="transferInvestmentLoader"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tag Modal -->

                    <div class="modal fade" :id="'tagModal'+tagIndex" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6 class="modal-title" id="exampleModalLabel">Create a tag</h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <input type="text" v-model="customTag" class="form-input text-center"
                                        placeholder="Type tag label" minlength="1" maxlength="26" style="padding:10px">
                                </div>
                                <div class="modal-footer" style="background:#fff">
                                    <div class="display-div">
                                        <div data-dismiss="modal" class="text-center" style="flex-basis:50%"
                                            aria-label="Close">
                                            <p style="color:#000">{{tran.cancel}}</p>
                                        </div>

                                        <div class="text-center" style="flex-basis:50%">
                                            <p style="color:#0094FF" @click="addTag(null,null,true)">Save<i
                                                    class="fa fa-spin fa-spinner" v-if="tagLoader"></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- transaction history Modal -->

                    <div class="modal fade" :id="'historyModal'+indexFromTable" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" style="max-width: 450px;">
                            <div class="modal-content" style=" border-radius: 10px;">

                                <div class="modal-body"
                                    style=" background:linear-gradient(180deg, #25A9DD 0%, #5AB19A 95.02%), #FFFFFF;border-radius: 10px 10px 0px 0px;">
                                    <div style="display:flex">
                                        <div style="flex-basis:20%; display:flex; align-items:center; min-height:30px"
                                            class="mr-4">
                                            <img :src="imgUrlFromServer+ portfolioObjectData.product_image" height="50"
                                                style="    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 2px solid #B7F2B6;">
                                        </div>
                                        <div style="flex-basis:80%">
                                            <h5 style="color:#FFE8B3; font-size:20px !important">
                                                {{portfolioObjectData.name}}
                                            </h5>
                                            <p style="color:#fff;font-size:12px ">{{portfolioObjectData.atp_name}}</p>
                                            <p class="pt-2" style="color:#fff">Purchased <span style="color:#B7F2B6">
                                                    {{portfolioObjectData.created_at | momentFormat}}</span></p>
                                            <hr style="border-top:1px solid #E0E0E0">



                                            <p style="color:#fff" v-if="!portfolioObjectData.isProductMatured"><span
                                                    style="color:#B7F2B6">{{portfolioObjectData.units_available}}
                                                </span> of {{portfolioObjectData.units_bought}} Unit(s) remaining</p>

                                            <p style="color:#fff" v-else><span
                                                    style="color:#B7F2B6">{{portfolioObjectData.units_available}}
                                                </span> Unit(s)</p>

                                            <template v-if="!portfolioObjectData.isProductMatured">
                                                <p class="pt-2" style="color:#fff"><span style="color:#B7F2B6"
                                                        v-if="!transferInvestmentLoader"><span>&#8358;</span>
                                                        {{formatAmount(transHistoryData.expected_return)}}</span>
                                                    Expected return at maturity</p>

                                            </template>
                                            <template v-else>
                                                <p class="pt-2" style="color:#fff">Investment mature <img class="pl-1"
                                                        src="../../assets/images/swoosh.png" height="13"></p>



                                            </template>

                                            <!-- 
                                                                     <template v-if="portfolioObjectData.staggeredActive">

                                                                         <div class="alert alert-light mt-1" role="alert" style="margin-bottom:0rem">


                                                                         <div>
                                                                             <span>
 <i class="fa fa-info-circle pr-2"  title="This is meant to show you the current state of your unpaid  investment" ></i> Unpaid investment status

                                                                             </span>
                                                                             <hr style="margin:0.2rem 0rem">
                                                                             <p class="pt-2 pb-1" >
                                                                                    <span>
                                                                                 <i class="fa fa-info-circle pr-2 "  style="opacity:0"  ></i> 
                                                                                   </span>
 Paid amount: <span>&#8358;</span><span > {{formatAmount(portfolioObjectData.staggeredPay)}}</span>

                                                                             </p>
                                                                             <p>
                                                                                    <span>
                                                                                 <i class="fa fa-info-circle pr-2"  style="opacity:0"  ></i> 
                                                                                   </span>
 Amount left: <span>&#8358;</span><span > {{formatAmount(portfolioObjectData.remainingPay)}}</span>

                                                                             </p>
                                                                         </div>
                                                                            
                                                                         </div>
                                                                     </template> -->
                                        </div>

                                        <div style="flex-basis:10%; ">

                                            <img class="point-cursor" data-dismiss="modal" aria-label="Close"
                                                src="../../assets/images/close.png"
                                                style="position: absolute;right: 11px;" height="20"
                                                @click="emptyTransHistory">
                                        </div>



                                    </div>
                                </div>
                                <div class="modal-footer"
                                    style="background:#fff;padding: 0rem; flex-direction:column; display:flex; align-items:flex-start;border-radius: 0px 0px 10px 10px; overflow:hidden">


                                    <div style="display:flex; border-top:1px solid #f2f2f2; min-width: 100%; color:#000;">
                                            <div style="flex-basis:50%;padding:10px;border-bottom: 1px solid #F0F0F0; border-right: 1px solid #F0F0F0; padding:20px " @click="hidePayout">
                                             <p :class="{'payout-active':!payout}">Activity</p>
                                            
                                            </div>
                                            <div style="flex-basis:50%;border-bottom: 1px solid #F0F0F0; border-left: 1px solid #F0F0F0; padding:20px " @click="changePayout">
                                                 <p :class="{'payout-active':payout}">Payout</p>
                                           
                                             </div>
                                         </div>




                                    <div class="text-left"
                                        style="background:#F0F0F0;min-width:100%; min-height:30px; padding:10px;border-bottom:1px solid #E0E0E0; ">
                                   
                                        <p style="color:#000;font-size:13px " v-if="!payout">Recent activities are on top</p>
                                        <p style="color:#000;font-size:13px " v-if="payout">Expected payout structure of this investment</p>
                                    </div>
                                    <div class="text-center pt-2 pb-2" style="min-width:100%"
                                        v-if="transferInvestmentLoader">
                                        <i class="fa fa-spin fa-spinner" style="color:#000"></i>
                                    </div>

                                    <div style="min-width:100%;"
                                        :class="{'add-max-height':transHistoryDataHistory.length > 3}" v-if="!payout">
                                        <div style="display:flex; min-width:100%; color:#000;"
                                            v-for="(data, index) in transHistoryDataHistory" :key="index">

                                            <div style="border-right: 1px solid #333333; padding:10px; position:relative;flex-basis: 25%;"
                                                :class="{'flex-45':windowWidth <= 372}">
                                                <div class="display-flex"
                                                    style="flex-direction:column;     justify-content: center;min-height: 40px;">
                                                    <img src="../../assets/images/ellip.png" height="11"
                                                        style="    right: -6px;position: absolute;top: 25px;">
                                                    <p style="color:#4F4F4F">{{data.created_at | dateTime}}</p>
                                                </div>
                                            </div>
                                            <div class="ml-3"
                                                style=" padding:10px 0px; border-bottom:1px solid #E0E0E0;flex-basis: 45%;">
                                                <div class="display-flex"
                                                    style="flex-direction:column; align-items: flex-start">

                                                    <template v-if="data.action_type == 'purchase'">
                                                        <p>Investment Purchase </p>
                                                        <p style="font-size:12px"><span style="opacity:0.6">Bought
                                                                in</span> {{data.extra}}</p>
                                                    </template>

                                                    <template
                                                        v-if="data.action_type == 'liquidation' && data.units_left > 0">
                                                        <p>Part Liquidation</p>
                                                        <p style="opacity:0.6; font-size:12px">Sale made via Market</p>
                                                    </template>

                                                    <template
                                                        v-if="data.action_type == 'liquidation' && data.units_left < 1">
                                                        <p>Full Liquidation</p>
                                                        <p style="opacity:0.6; font-size:12px">Sale made via Market</p>
                                                    </template>


                                                    <template v-if="data.action_type == 'mature'">
                                                        <p style="color:#58B46F">Investment Mature</p>
                                                        <p style="font-size:12px">
                                                            <span style="color:#58B46F">
                                                                <span>&#8358;</span>
                                                                {{formatAmount(transHistoryData.expected_return)}}</span>
                                                            <span style="opacity:0.6; font-size:12px" v-if=" staggeredArray == null"> returns paid to
                                                                wallet</span>
                                                        </p>
                                                    </template>

                                                    <template v-if="data.action_type == 'transfer'">
                                                        <p>Investment Transfer</p>
                                                        <p style="opacity:0.6; font-size:12px">Sent to {{data.extra}}
                                                        </p>
                                                    </template>
                                                    <template v-if="data.action_type == 'transfer_in'">
                                                        <p>Investment Transfer</p>
                                                        <p style="opacity:0.6; font-size:12px">Received via Secondary
                                                            Market</p>
                                                    </template>

                                                    <div v-if="windowWidth <= 372">
                                                        <p style="font-size:18px"> <span
                                                                v-if="data.action_type == 'purchase'">+</span>
                                                            <span
                                                                v-if="data.action_type == 'liquidation' && data.units > 0 ||  data.action_type == 'transfer'">-</span>{{data.units}}
                                                            <span v-if="data.units > 1" style="opacity:0.6;">

                                                                units</span>
                                                            <span v-else style="opacity:0.6;">unit</span>
                                                        </p>
                                                        <p style="font-size:12px"
                                                            v-if="data.action_type !== 'purchase'">{{data.units_left}}
                                                            <span style="opacity:0.6;"><span
                                                                    v-if="data.units_left > 1">units </span> <span
                                                                    v-else>unit</span> left</span></p>
                                                    </div>



                                                </div>

                                            </div>
                                            <div style=" padding:10px; border-bottom:1px solid #E0E0E0;flex-basis: 25%;"
                                                v-if="windowWidth > 372">
                                                <div class="display-flex"
                                                    style="flex-direction:column; align-items: flex-end">
                                                    <p style="font-size:18px">

                                                        <span v-if="data.action_type == 'purchase'">+</span>
                                                        <span
                                                            v-if="data.action_type == 'liquidation' && data.units > 0 ||  data.action_type == 'transfer'">-</span>{{data.units}}
                                                        <span v-if="data.units > 1" style="opacity:0.6;">units</span>
                                                        <span v-else style="opacity:0.6;">unit</span>
                                                    </p>
                                                    <p style="font-size:12px" v-if="data.action_type !== 'purchase'">
                                                        {{data.units_left}} <span style="opacity:0.6;"><span
                                                                v-if="data.units_left > 1">units </span> <span
                                                                v-else>unit</span> left</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div style="border-top:1px solid #f2f2f2; min-width: 100%; 
                                color:#000;padding:10px" v-if=" staggeredArray == null && payout && !transferInvestmentLoader">
  <!-- <p>Investment in progress</p> -->
  <p>Matured. No additional information</p>
                                    </div>

                                <div style="display:flex; border-top:1px solid #f2f2f2; min-width: 100%; 
                                color:#000;padding:10px" v-if="payout && !transferInvestmentLoader" v-for="data in staggeredArray" :key="data.row_id">
                                   <div style="flex-basis:50%">
                                    <p v-if="data.type == 'capital_with_roi'">Capital with ROI</p>
                                    <p v-if="data.type == 'capital'">Capital</p>
                                    <p v-if="data.type == 'interest'">Interest</p>
                                    <p>{{data.roi}}%</p>
                                   </div>
                                   <div style="flex-basis:50%">
                                        <p>{{data.comment}}</p>
                                    <!-- <p style="opacity:0.6">Paid</p> -->
                                    </div>
                                </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- cancel transfer modal -->
                    <div class="modal fade" :id="'cancelTransfer' + indexFromTable" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header text-right">
                                    <h4 class="modal-title" id="exampleModalLongTitle">Cancel investment transfer</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p> You’re about stop the transfer of:</p>
                                    <span class="fw-600"> {{ portfolioObjectData.name }} </span>

                                    <h6 class="mt-2"
                                        v-if="portfolioObjectData.transfer_data !== null && portfolioObjectData.transfer_data !== null">
                                        {{portfolioObjectData.transfer_data.units_transferred}} unit(s) -
                                        <span>&#8358;</span>{{formatAmount(portfolioObjectData.transfer_data.units_transferred*5000)}}

                                    </h6>

                                    <p style="color:#828282" class="mt-2">

                                        from your portfolio to:
                                    </p>

                                    <h6>

                                        {{portfolioObjectData.transfer_data.receiver_email}}
                                    </h6>

                                </div>

                                <div class="modal-footer text-center" style="background:#fff">
                                    <div class="display-div">
                                        <div data-dismiss="modal" aria-label="Close">
                                            <p style="color:#000">{{tran.cancel}}</p>
                                        </div>

                                        <div>
                                            <p style="color:#0094FF" @click="cancelTransfer">Yes, cancel transfer<i
                                                    class="fa fa-spin fa-spinner" v-if="transferInvestmentLoader"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Wrapper>

    </div>
</template>

<script>
    import Navbar from '../partials/Navbar'
    import Sidebar from '../partials/Sidebar'
    import Footer from '../partials/Footer'
    import Wrapper from '../partials/Wrapper'
    import trans from "../../../translations";
    import http from "../../http/index";
    import api from "../../apis/index";
    import {
        commonJs
    } from './mixins/commonJs.js';

    import {
        mapState
    } from "vuex";

    import moment from "moment";

    export default {
        mixins: [commonJs],
        data() {

            return {
                tran: {},
                dropDownId: null,
                currentPage: 1,
                totalPages: 0,
                accruedMinAmountForResell: '',
                indexFromTable: '',
                penaltyAmount: '',
                transHistoryData: '',
                activeMarket: 'all',
                userData: [{

                    id: '',
                    name: '',
                    age: '',
                    street: '',
                    state: '',
                    city: ''
                }],
                filters: {
                    name: {
                        value: '',
                        keys: ['name', 'age', 'street', 'state', 'city']
                    }
                },
                noResult: false,
                unitsToSell: '',
                totalAmountToSell: 0,
                transHistoryDataHistory: [],
                remainvalueUponSale: 0,
                estimatedInterest: 0,
                remainingUnits: '',
                alreadyEarned: 0,
                saleAmountAfterDeduction: 0,
                portfolioObjectData: {

                    amount: '',
                    transfer_data: {
                        transfer_charge: '',
                        receiver_email: ''
                    }
                },
                filterActive: false,
                cartItemSelectedId: '',
                activeTransType: 'both',
                hideEye: false,
                hoverState: false,
                showEye: true,
                hideInput: "password",
                userPin: '',
                sellProd: true,
                sellProdSum: false,
                editTransferDetailBtn: false,
                transferDetail: true,
                stopTransfer: true,
                transferRecipientEmail: '',
                transferInvestmentLoader: false,
                filterString: '',
                recepientTransferDetail: {

                    first_name: '',
                    last_name: '',
                    charge: '',
                    message: ''
                },

                tagId: null,
                tagIndex: null,

                tagClicked: null,
                customTag: false,
                productPicked: {},
                tagPicked: null,
                customTag: '',
                remTag: false,
                tagIcon: true,
                activeMarketString: 'All assets',
                payout:false,
                staggeredArray:[]
            }

        },




        methods: {
            openTransferHistoryModal(prod, index) {
                this.getObject(prod, index)
                $('#historyModal' + this.indexFromTable).modal('show');
            },

            changePayout(){
                this.payout = true

            },
            hidePayout(){
                this.payout = false

            },
            saveTransType(transType) {
                this.activeTransType = transType
                //this.filterPortfolioData(this.portf)
            },

            saveActiveMarketString(string) {

                this.activeMarketString = string
            },


            activateFilter() {

                this.filterActive = !this.filterActive
            },
            showRemTag(prod) {
                if (this.tagLoader) {

                    return;
                }


                else {
                    this.tagId = prod.id
                    this.remTag = true
                    this.tagIcon = false
                }

            },

            hideRemTag() {


                if (this.tagLoader) {
                    return;
                }

                else {
                    this.tagId = ''
                    this.remTag = false
                    this.tagIcon = true
                }
            },

            saveActiveBtnId(prod) {

                this.tagId = prod.id

            },
            showDropDown(e) {

                this.hoverState = true

            },
            hideDropDown(prod) {


                this.hoverState = false

            },

            saveDropDownId(prod) {
                this.dropDownId = prod.id

            },


            resetTransferState() {

                this.transferDetail = true
                this.editTransferDetailBtn = false
                this.unitsToSell = ''
                this.transferRecipientEmail = ''
                this.totalAmountToSell = 0

            },
            transferInProgress() {

                if (this.transferRecipientEmail == '' || this.unitsToSell == '') {
                    this.stopTransfer = true

                } else {

                    this.stopTransfer = false
                }
            },
            emptyTransHistory() {

                this.transHistoryDataHistory = []
                this.payout = false 
            },
            getTransHistory(prod) {
                this.emptyTransHistory()
                let vm = this
                this.transferInvestmentLoader = true


                http.get(api.getTransHistory,

                    {
                        params: {

                            tranx_id: prod.id,

                        }
                    }

                )
                    .then(response => {

                        this.$helpers.log(response, 'trans history');
                        this.transHistoryData = response.data.data
                        this.transHistoryDataHistory = response.data.data.history.sort(function (a, b) {
                            return moment(b.created_at) - moment(a.created_at);
                        })
                        vm.transferInvestmentLoader = false

                            this.staggeredArray = JSON.parse(response.data.data.staggered_payment_plan)

                    })
                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },
            checkTransferRecepient() {

                let vm = this
                this.transferInvestmentLoader = true

                let apiData = {

                    transaction_id: this.portfolioObjectData.id,
                    email: this.transferRecipientEmail,
                    units: this.unitsToSell
                }
                http.get(api.transferInvestment,

                    {
                        params: {

                            transaction_id: this.portfolioObjectData.id,
                            email: this.transferRecipientEmail,
                            units: this.unitsToSell
                        }
                    }

                )
                    .then(response => {

                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false

                        this.recepientTransferDetail = response.data.data
                        this.recepientTransferDetail.message = response.data.message
                        this.transferDetail = false
                        this.editTransferDetailBtn = true

                    })

                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },

            sellInvestment() {
                let vm = this
                this.transferInvestmentLoader = true

                let data = {
                    tranx_id: this.portfolioObjectData.id,

                    units: this.unitsToSell,
                    pin: this.userPin
                }
                if (this.portfolioObjectData.asset_tag == 'euro_bond') {

                    delete data.units
                }



                http.delete(
                    this.portfolioObjectData.asset_tag == 'euro_bond' ? api.getTransaction : api.sellProduct, {
                        data: data
                    })



                    .then(response => {

                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false

                        $('#sellModal' + this.indexFromTable).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false
                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 10000,
                            message: this.unitsToSell + ` unit(s) of ` + this.portfolioObjectData.name + ` has been put up for sale `,
                            position: "top center"
                        });
                        this.sellProd = true
                        this.sellProdSum = false

                        this.userPin = ''
                        this.unitsToSell = ''
                    })

                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },

            transferInvestment() {
                let vm = this
                this.transferInvestmentLoader = true

                let apiData = {

                    transaction_id: this.portfolioObjectData.id,
                    email: this.transferRecipientEmail,
                    units: this.unitsToSell,
                    pin: this.userPin
                }
                http.post(api.transferInvestment, apiData)
                    .then(response => {

                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false

                        $('#transfer' + this.indexFromTable).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$helpers.log(response);

                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 8000,
                            message: this.transferRecipientEmail + ` portfolio will be updated upon confirmation.`,
                            position: "top center"
                        });
                        this.transferDetail = true
                        this.editTransferDetailBtn = false

                        this.transferRecipientEmail = ''
                        this.userPin = ''
                        this.unitsToSell = ''
                    })

                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },

            cancelTransfer() {
                let vm = this
                this.transferInvestmentLoader = true

                let apiData = {

                    tranx_id: this.portfolioObjectData.id,
                    transfer_id: this.portfolioObjectData.pending_transfer_id,
                }
                http.patch(api.transferInvestment, apiData)
                    .then(response => {

                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false

                        $('#cancelTransfer' + this.indexFromTable).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$helpers.log(response);

                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 8000,
                            message: "Investment transfer cancelled",
                            position: "top center"
                        });
                        this.transferDetail = true
                        this.editTransferDetailBtn = false

                        this.transferRecipientEmail = ''
                        this.userPin = ''
                        this.unitsToSell = ''
                    })

                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },

            addTag(tag, prod, customTagStatus) {
                if (!customTagStatus) {

                    this.tagClicked = tag.id

                }

                this.$store.commit("market/startTagLoader")

                if (customTagStatus) {

                    var apiObject = {

                        tranx_id: this.productPicked.prod.id,

                        custom_tag: this.customTag
                    }

                }
                else {

                    var apiObject = {

                        tranx_id: prod.id,
                        tag_id: tag.id,
                    }
                }


                http.post(api.tags, apiObject)
                    .then(response => {
                        this.closeEvent()
                        this.$helpers.log(response);
                        this.$store.commit("market/stopTagLoader")

                        $('#tagModal' + this.tagIndex).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$store.dispatch('market/getTags')
                        this.$helpers.log(response);

                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 12000,
                            message: "Investment tag added",
                            position: "top center"
                        });

                    })

                    .catch(error => {


                        this.$helpers.log(error.response)
                        this.$store.commit("market/stopTagLoader")

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })


            },






            deleteTag(prod, event) {




                this.tagId = prod.id

                this.$store.commit("market/startTagLoader")

                let data = {

                    tranx_id: prod.id,

                }



                http.delete(api.tags, { data })
                    .then(response => {

                        this.$helpers.log(response);
                        this.$store.commit("market/stopTagLoader")
                        this.closeEvent()
                        this.tagId = ''
                        $('#tagModal' + this.tagIndex).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$store.dispatch('market/getTags')
                        this.$helpers.log(response);

                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 8000,
                            message: "Tag removed",
                            position: "top center"
                        });

                    })

                    .catch(error => {


                        this.$helpers.log(error.response)
                        this.$store.commit("market/stopTagLoader")

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })


            },


            cancelSale() {
                let vm = this
                this.transferInvestmentLoader = true

                let apiData = {

                    tranx_id: this.portfolioObjectData.id,

                }
                http.patch(api.cancelSale, apiData)
                    .then(response => {

                        this.$helpers.log(response);
                        this.transferInvestmentLoader = false

                        $('#accruedInterest' + this.indexFromTable).modal('hide');
                        $('.modal-backdrop').remove()
                        this.$store.dispatch('market/getPortfolio')
                        this.$helpers.log(response);

                        this.$toast.success({
                            title: this.tran.success,
                            icon: require("../../assets/images/successicon.png"),
                            timeOut: 8000,
                            message: "Ongoing Sale cancelled",
                            position: "top center"
                        });
                        
                        this.transferDetail = true
                        this.editTransferDetailBtn = false

                        this.transferRecipientEmail = ''
                        this.userPin = ''
                        this.unitsToSell = ''
                    })

                    .catch(error => {

                        vm.transferInvestmentLoader = false
                        this.$helpers.log(error.response)

                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    })

            },
            editSale() {

                this.sellProd = true
                this.sellProdSum = false
                this.unitsToSell = ''

            },

            cancelSaleModal() {

                this.sellProd = true
                this.sellProdSum = false
                this.unitsToSell = ''
                this.totalAmountToSell = 0
                this.remainvalueUponSale = 0
                this.estimatedInterest = 0
                this.remainingUnits = 0

            },
            gotoSellProdSum() {

                this.sellProd = false
                this.sellProdSum = true
            },
            editTransferDetail() {
                // this.unitsToSell = ''
                this.transferDetail = true
                //   this.totalAmountToSell = 0
                this.editTransferDetailBtn = false
            },
            proceedToTransfer() {

                this.transferDetail = false
                this.editTransferDetailBtn = true

            },

            createPin() {
                $('.modal-backdrop').removeClass("modal-backdrop")
                this.$router.push('/create/pin')
            },
            currentInvestmentValue(amount, roi, opens_at, maturity_date) {
                let date1 = moment(opens_at);
                let date2 = moment(maturity_date);
                let dateOneDifference = date2.diff(date1, "days");

                let dateTwo = moment(new Date());

                let dateTwoDifference = dateTwo.diff(date1, "days");

                // (amount*roi)/

                this.alreadyEarned = ((amount * roi) / (dateOneDifference * dateTwoDifference)).toFixed(2)

            },

            checkUnitsToSell(unitsAvalable, investedAmount, roi, currentInvestmentWorth, prod) {

                this.transferInProgress()

                this.totalAmountToSell = this.unitsToSell * 5000

                this.remainvalueUponSale = investedAmount - this.totalAmountToSell
                this.remainingUnits = unitsAvalable - this.unitsToSell

                let rate = roi * 0.01;



                let date1 = moment(prod.created_at);
                let date2 = moment(this.serverTime);
                let date3 = moment(prod.matures_at);
                let daysSpent = date2.diff(date1, "days")
                let daysOfInvestment = date3.diff(date1, "days")
                let interestGottenTillNow = rate / daysOfInvestment
                interestGottenTillNow = interestGottenTillNow * investedAmount

                let dailyInterest = roi / daysOfInvestment

                let percentValue = dailyInterest * daysSpent

                let amountAcruedFromSoldValue = (percentValue / 100) * this.totalAmountToSell

                let finalAmount = rate * this.remainvalueUponSale;

                this.estimatedInterest = parseFloat(amountAcruedFromSoldValue + finalAmount).toFixed(2)

                let penaltyCharge = 3 * 0.01

                penaltyCharge = penaltyCharge * this.totalAmountToSell
                this.saleAmountAfterDeduction = this.totalAmountToSell - penaltyCharge

                if (this.unitsToSell > unitsAvalable || this.unitsToSell == '' || this.unitsToSell == 0) {

                    if (this.unitsToSell > unitsAvalable) {
                        this.$toast.warn({
                            timeOut: 5000,
                            icon: require("../../assets/images/cautionicon.png"),
                            title: this.tran.sorry,
                            message: 'Please put it in a number less than or equal to the available unit',
                            position: "top center"
                        });
                    }
                    this.unitsToSell = ''
                    this.totalAmountToSell = 0
                    this.remainvalueUponSale = 0
                    this.remainingUnits = 0
                    this.estimatedInterest = 0
                }
            },
            showInputText() {
                this.hideInput = "text";
                this.showEye = false;
                this.hideEye = true;
            },

            hideInputText() {
                this.hideInput = "password";
                this.showEye = true;
                this.hideEye = false;
            },

            sellProduct() {
                this.$store.dispatch("market/sellProduct", this.cartItemSelectedId);
            },

            addToUsers() {
                this.users.unshift(this.userData);
            },

            openTagModal(prod, index, tag) {

                this.productPicked = {

                    prod: prod,
                    tag: tag,
                },

                    this.tagIndex = index

            },

            getObject(prod, index) {

                this.currentInvestmentValue(prod.amountInvested, prod.roi, prod.opens_at, prod.matures_at)
                this.cartItemSelectedId = prod.id;
                this.portfolioObjectData = prod;
                this.indexFromTable = index;
                // alert('hello')
                this.penaltyAmount = (3 * 0.01) * prod.amountInvested
                this.getAccruedInterest(prod)
            },

            getAccruedInterest(prod) {
                let dateBought = moment(prod.created_at);
                let presentDay = moment(new Date());
                let tenor = moment(prod.matures_at)

                let dateOpen = moment(prod.opens_at)

                let tenorOfProduct = tenor.diff(dateOpen, "days");

                let daysAcc = presentDay.diff(dateBought, "days");

                let rateOfUser = prod.roi * 0.01;

                let amountDue = rateOfUser * prod.amountInvested

                let expRoi = daysAcc / tenorOfProduct

                this.accruedMinAmountForResell = (amountDue * expRoi).toFixed(2)

            },

            getWithdrawFundValue(e) {
                let fundValue = e.target.value;
                e.target.value = parseInt(
                    fundValue.replace(/\D/g, ""),
                    10
                ).toLocaleString();

                this.fundAmount = e.target.value
            },

            searchTag(tag) {
                this.clearTableData()
                this.activeMarket = 'all'
                this.filterString = tag

            },

            clearSearchTag() {

                this.filterString = ''
                this.activeTransType = 'both'
                this.noResult = false

            },

            clearTableData() {

                this.activeTransType = 'both'
                this.noResult = false

            },

            filterPortfolioData(tableData) {

                if (this.activeMarket == 'all' && this.filterString == '' && this.activeTransType == 'both') {

                    return tableData
                }

                if (this.activeMarket !== 'all' && this.filterString == '') {

                    return tableData.filter(prod => {

                        if (this.activeTransType == 'active') {

                            if (prod.currency_denomination == this.activeMarketValue && !prod.isProductMatured || prod.asset_tag == this.activeMarketValue && !prod.isProductMatured) {

                                return prod;
                            }
                        }
                        else if (prod.currency_denomination == this.activeMarketValue || prod.asset_tag == this.activeMarketValue) {
                            return prod;
                        }


                    });
                }


                if (this.filterString !== '' && this.activeTransType == 'both') {

                    return tableData.filter(prod => {


                        if (prod.name.toLowerCase().includes(this.filterString.toLowerCase()) || prod.tag_name !== null && prod.tag_name.toLowerCase().includes(this.filterString.toLowerCase())) {


                            return prod;
                        } else {
                            this.noResult = true

                            return false;
                        }

                    });

                }
                if (this.returnActiveTransType == 'active') {

                    return tableData.filter(prod => {

                        if (this.filterString !== '') {

                            if (!prod.isProductMatured && prod.tag_name !== null && prod.tag_name.toLowerCase().includes(this.filterString.toLowerCase())) {

                                return prod
                            }

                        }

                        else {

                            if (!prod.isProductMatured) {

                                return prod
                            }
                        }


                    })
                }
                else {
                    return tableData
                }

            },

            sendToMarketPlace() {
                this.$router.push('/market');
            },
            saveActiveMarket(marketType) {
                this.activeTransType = 'both'

                this.activeMarket = marketType
                this.filterString = ''
            },
            closeEvent() {
                if (!this.tagLoader) {

                    this.tagId = ''
                    this.dropDownId = ''
                }

            }
        },

        components: {
            Navbar,
            Footer,
            Sidebar,
            Wrapper,
        },

        computed: {
            ...mapState({
                transferNotification: state => state.auth.transferNotification,
                portf: state => state.market.portfolioTransactions,
                portfolioSummary: state => state.market.portfolioSummary,
                allTags: state => state.market.allTags,
                serverTime: state => state.market.serverTime,
                tagLoader: state => state.market.tagLoader,
                portPreLoader: state => state.market.portPreLoader,
                notificationMessageFromAuth: state => state.auth.notificationMessageFromAuth,
                transferProductId: state => state.auth.transferProductId,
            }),
            activeMarketValue() {

                return this.activeMarket
            },

            returnActiveTransType() {

                return this.activeTransType
            },
            returnFilter() {

                return this.noResult
            },
            returnAmountOfPenalty() {

                return this.penaltyAmount
            }
        },

        deactivated() {
            this.$destroy()
        },
        mounted() {

            this.$store.dispatch('market/getTags')

            let heading = {

                main: this.tran.portfolio,
                sub: this.tran.product_purchased
            }
            this.$store.commit('market/savePageHeading', heading)
            this.tran = trans;

            // this.$store.dispatch('market/getAccountOverview')
            this.$store.commit('market/stopIsFetching')

            if (this.transferNotification) {
                this.$toast.success({
                    title: this.tran.success,
                    icon: require("../../assets/images/successicon.png"),
                    timeOut: 5000,
                    message: this.notificationMessageFromAuth,
                    position: "top center"
                });
                this.$store.commit('auth/closetransferNotification')
            }
            this.$store.commit('auth/saveCurrentRoute', this.$route.path)
            let vm = this

            // document.eventListener("click", ()=>{

            // vm.hoverState = false

            // })
            $(document).on("click", function (e) {

                vm.hoverState = false
            })
        },

        beforeCreate() {

            this.$store.dispatch('market/getPortfolio')
            this.$store.commit('market/checkSearchObject', 'portfolio')
        },

        beforeDestroy() {
            this.$store.commit("market/startPreloader");

            this.$store.commit("market/startIsFetching");
            this.$store.commit("market/removePageHeading");
        },

    }
</script>

<style lang="scss" scoped>
    .hide-btn {

        display: none !important;
    }

    .show-btn {

        display: block !important;

    }

    .tag-btn {

        background: #F1FAF0;
        min-height: 30px;
        border: 1px solid #F1FAF0;
        border-radius: 10px;
        color: #4F4F4F;
        min-width: 100px;
        text-align: left;
        max-width: 100px;
        padding: 0px 8px;
    }

    @media (max-width: 991px) {

        .tag-btn {
            min-width: 90px;
            max-width: 115px;
        }
    }



    .hover-item {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        min-height: 15px;
        align-items: center;
        justify-content: space-between;
        transition: 0.5s;

    }

    .hover-item:hover {

        transition: 0.5s;
        background: #f2f2f2;
    }


    .sm-height {
        min-height: 84px;
    }

    .lng-height {
        height: 150px;
        overflow-y: scroll !important;
    }

    .flex-22 {

        flex-basis: 22% !important;
    }

    .flex-36 {

        flex-basis: 36% !important;
    }

    .flex-38 {

        flex-basis: 38% !important;
    }

    .flex-45 {

        flex-basis: 45% !important;
    }

    .add-max-height {
        overflow-y: scroll;
        height: 227px;
    }

    .trans-footer {

        padding: 1rem 2em !important;

        @media (max-width: 767px) {

            padding: 1rem 0.6em !important;
        }
    }

    .eye-div i {

        left: 94%;

    }

    .tran-height-port {

        height: 36px;
        transition: height 0.5s ease;
        overflow: hidden;

    }

    .filter-active {

        height: 97px;
        min-width: 100%;


    }
    .payout-active{

        color:#0094FF;
    }

    .fa {
        transition: transform .5s;

    }
</style>